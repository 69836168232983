import React from "react"

export type LinkTargetProps = {
  ids: string[]
}

const LinkTarget: React.FC<LinkTargetProps> = ({ ids }) =>
  <span>{ids.map(id => <span id={id} key={id} />)}</span>

export default LinkTarget
