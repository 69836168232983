import { MdArrowForward } from "@react-icons/all-files/md/MdArrowForward"
import { Link } from "gatsby"
import React from "react"

export type ReadMoreProps = {
  href: string
  text: string
}

const ReadMore: React.FC<ReadMoreProps> = ({ href, text }) => {
  return <Link to={href} itemProp="url" className="read-more-link">read more about {text}<MdArrowForward /></Link>
}

export default ReadMore
